#questions h1 {
    font-size: 18px;
    font-size: 1rem;
    text-transform: uppercase;
}

#questions p, #questions li, #questions textarea {
    font-size: 16px;
    font-size: 0.88889rem;
}

#questions .indicator, #questions .btn {
    font-size: 18px;
    font-size: 1rem;
}

#questions .alternative-answer ul {
    list-style-type: none;
}

#questions .alternative-answer li {
    cursor: pointer;
    transition-duration: 0.2s;
}

#questions .alternative-answer li:hover {
    background-color: #6B98B7 !important;
    color: #fff !important;
}

#questions .alternative-answer li.selected {
    background-color: #6B98B7 !important;
    color: #fff !important;
}

#questions .alternative-answer li .circle {
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: #E5E5E5 solid 1px;
}

#questions .alternative-answer li.selected .circle {
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #2c5f82;
    border: #105D93 solid 1px;
}