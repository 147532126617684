#header-restrict-area {
    border-bottom: #E5E5E5 solid 1px;
    z-index: 997;
}

#header-restrict-area .notifies {
    width: 300px;
    max-height: 350px;
    overflow: auto;
}

#header-restrict-area .notify {
    display: block;
    font-size: 11px;
    font-size: 0.61111rem;
    position: absolute;
    margin-top: -18px;
    margin-left: 8px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

#header-restrict-area .dropdown-menu {
    min-width: 250px;
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        font-size: 15px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        margin-top: -43px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        margin-left: 12px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        width: 23px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        height: 23px !important;
    }
}

#header-restrict-area .top-items {
    list-style-type: none;
}

#header-restrict-area .top-items li {
    display: inline-block;
}

#header-restrict-area .top-items a {
    text-decoration: none;
}

#header-restrict-area .nicename {
    font-size: 14px;
    font-size: 0.77778rem;
}

#header-restrict-area .profile-image {
    background-color: #6B98B7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}