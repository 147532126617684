#activity-list .notification {
    font-size: 12px;
    font-size: 0.66667rem;
    position: absolute;
    right: 0;
    top: -10px;
}

#activity-list .card-activity-student {
    text-decoration: none;
}

#activity-list .card-activity-student:hover {
    text-decoration: none;
}

#activity-list .card-activity-student .card-body {
    position: relative;
}

#activity-list .new-activity .arrow-right {
    margin-top: 7px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #30e360;
    position: absolute;
    left: 0;
    z-index: 1;
}

#activity-list .activity-in-atention .arrow-right {
    margin-top: 7px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #da0015;
    position: absolute;
    left: 0;
    z-index: 1;
}

#activity-list .card-activity div {
    border: #E5E5E5 solid 1px;
}

#activity-list h3 {
    font-size: 16px;
    font-size: 0.88889rem;
}

#activity-list .discipline {
    font-size: 13px;
    font-size: 0.72222rem;
}

#activity-list p {
    margin-bottom: 0px;
}

#activity-list .info {
    font-size: 15px;
    font-size: 0.83333rem;
}

#activity-list .access-activity {
    font-size: 16px;
    font-size: 0.88889rem;
}