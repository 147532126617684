#embed-live .open-zoom {
    position: absolute;
    right: 20px;
    top: -20px;
    font-size: 22px;
    font-size: 1.22222rem;
    z-index: 996;
    text-decoration: none;
}


#embed-live .open-zoom .arrow-right {
    margin-top: 20px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #30e360;
    position: absolute;
    left: 0;
}

#embed-live .open-zoom:hover {
    text-decoration: none;
    filter: brightness(60%);
}

#embed-live .video {
    min-height: 390px;
    position: relative;
}