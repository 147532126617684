#last-activities .item {
    border: #E5E5E5 solid 1px;
    border-radius: 5px !important;
    text-decoration: none;
}

#last-activities .item:hover {
    text-decoration: none;
    background-color: #f3f3f3;
}

#last-activities .item .ballon-new {
    position: absolute;
    right: 0;
    margin-top: -23px;
    font-size: 12px;
    font-size: 0.66667rem;
}

@media (max-width: 576px) {
    #last-activities .item .ballon-new {
        right: 0;
    }
}

@media (max-width: 576px) {
    #last-activities .item .ballon-new {
        top: 21px;
    }
}

#last-activities .item .ballon-new.bg-success .arrow-right {
    margin-top: 0px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #30E360;
    position: absolute;
    left: 0;
    z-index: 1;
}

#last-activities .item .ballon-new.bg-danger .arrow-right {
    margin-top: 0px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #DA0015;
    position: absolute;
    left: 0;
    z-index: 1;
}

#last-activities .title {
    font-size: 16px;
    font-size: 0.88889rem;
}

#last-activities .teacher {
    font-size: 13px;
    font-size: 0.72222rem;
}

#last-activities .start {
    font-size: 15px;
    font-size: 0.83333rem;
}

#last-activities .more-activities {
    text-transform: uppercase;
    font-size: 16px;
    font-size: 0.88889rem;
}