#slider .slide {
    height: 300px;
}

#slider {
    position: relative;
}

#slider .slick-prev {
    position: absolute;
    left: 2.5%;
    z-index: 2;
}

#slider .slick-prev::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f104';
    font-size: 32px;
}

#slider .slick-next {
    position: absolute;
    right: 2.5%;
    z-index: 2;
}

#slider .slick-next::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f105';
    font-size: 32px;
}

#slider .slick-dots {
    bottom: -30px;
}

#slider .slick-dots li {
    margin: 0 2px;
    opacity: 1;
    height: 15px;
    width: 15px;
}

#slider .slick-dots li button {
    opacity: 1;
    height: 15px;
    width: 15px;
    padding: 0;
}

#slider .slick-dots li button::before {
    background-color: #F6F7FB;
    border-radius: 50%;
    content: "";
    opacity: 1;
    font-size: 12px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#slider .slick-dots li.slick-active button {
    opacity: 1;
}

#slider .slick-dots li.slick-active button::before {
    background-color: #fff;
    opacity: 1;
}