#shortcuts .btn-sc-activities, #shortcuts .btn-sc-files, #shortcuts .btn-sc-tutorials {
    border-radius: 5px !important;
    text-decoration: none;
}

#shortcuts .btn-sc-activities h1, #shortcuts .btn-sc-files h1, #shortcuts .btn-sc-tutorials h1 {
    font-size: 30px;
    font-size: 1.66667rem;
    font-weight: 900;
}

#shortcuts .btn-sc-activities p, #shortcuts .btn-sc-files p, #shortcuts .btn-sc-tutorials p {
    font-size: 16px;
    font-size: 0.88889rem;
}