#type-of-activities ul {
    list-style-type: none;
}

#type-of-activities ul li {
    display: inline-block;
    font-size: 12px;
    font-size: 0.66667rem;
    cursor: pointer;
}

#type-of-activities ul .active {
    background-color: #6B98B7 !important;
}

#type-of-activities ul .active a {
    color: #fff !important;
}