#header-activity h1 {
    font-size: 18px;
    font-size: 1rem;
    text-transform: uppercase;
}

#header-activity h2, #header-activity p {
    font-size: 16px;
    font-size: 0.88889rem;
    text-transform: uppercase;
}

#header-activity p {
    margin-bottom: 0px;
}

