#sidebar-right h2 {
    font-size: 18px;
    font-size: 1rem;
}

#sidebar-right .label-answered, #sidebar-right .label-no-answered {
    display: block;
    width: 15px;
    height: 15px;
    float: left;
}

#sidebar-right .label-answered {
    background-color: #99D892;
}

#sidebar-right .label-no-answered {
    background-color: #6B98B7;
}

#sidebar-right #labels-activities p {
    font-size: 14px;
    font-size: 0.77778rem;
    margin-bottom: 0px;
}

#sidebar-right #labels-activities .labels {
    font-size: 15px;
    font-size: 0.83333rem;
}

#sidebar-right #prev-question:hover {
    text-decoration: none;
    background: #f6f6f6 !important;
}

#sidebar-right #prev-question p {
    font-size: 14px;
    font-size: 0.77778rem;
    margin-bottom: 0px;
}

#sidebar-right .far {
    color: #6B98B7;
}

#sidebar-right #prev-question {
    border: #6B98B7 solid 1px !important;
}

#sidebar-right .fas {
    color: #6B98B7;
}

#sidebar-right #prev-question.already-answered {
    border: #99D892 solid 1px !important;
}

#sidebar-right #prev-question.current {
    border: #343a40 dashed 1px !important;
}

#sidebar-right #prev-question.already-answered .fas {
    color: #99D892;
}