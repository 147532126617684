.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
    /*display: none;*/
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__label,
.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow{
    font-size: 2rem;
    margin-bottom: 2rem;
    border: 1px solid #dedede;
    padding: 1rem;
}

.react-calendar .react-calendar__tile {
    height: 5rem;
}

.react-calendar .react-calendar__month-view__weekdays__weekday {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
}

.react-calendar .react-calendar__tile--active {
    background: #d2d2d2;
    /*color: white;*/
}

.react-calendar .react-calendar__tile--active:enabled:hover,
.react-calendar .react-calendar__tile--active:enabled:focus {
    background: #d2d2d2;
    /*color: white;*/
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
    background: #929292;
    color: white;
}

.react-calendar .react-calendar__tile--now {
    background: #37c1d5;
    color: white;
}

.react-calendar .react-calendar__tile,
.react-calendar .react-calendar__month-view__days__day {
    font-size: 1rem;
}
