html, body, #root {
    background: #ebeef1 !important;
    height: 100%;
    display: block;
    color: #242424;
}

.slideout-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
}

.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}

.cursor-pointer:not(.disabled) {
    cursor: pointer
}

#modulosContainer optgroup::before {
    padding: 12px 5px 8px;
}