aside {
    border-right: #E5E5E5 solid 1px;
}

.SlideWrapper {
    max-width: 350px;
}

#menu ul {
    list-style-type: none;
}